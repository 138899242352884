.upload-box {
    display: inline-block;
    width: 70%;
    height: 100%;
    border: 1px solid dimgrey;
}

.upload-box p {
    margin: 0;
}

.upload-tooltip {
    display: flex;
    align-items: center;
    height: 100%;
    align-self: center;
    justify-content: center;
}
