#root {
    text-align: center;
    position: absolute;
    display: flex;
    align-items: center;
    align-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
